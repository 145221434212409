import * as React from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BigTile from "../atoms/BigTile";

const Products = (props) => {
    return <>
        {props.products?.length  !== 0 ?
            <>
               <Stack textAlign="center" py={4}>
                    {props.sectionTitles &&
                    <Typography variant='h2' color='primary.light'>
                    Products
                </Typography>}
                </Stack>
                {
                    props.products.map((product, index) => {
                        return <BigTile
                            key={'product-'+ index}
                            name={product.name}
                            description={product.description}
                            price={product.price}
                            image={props.images[index]?.node?.fluid?.src}
                        />
                    })}
            </>
            : undefined
        }
    </>;
}

export default Products