import * as React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Header from "../components/atoms/Header";
import Steps from "../components/organisms/Steps";
import Products from "../components/organisms/Products";
import Testimonials from "../components/organisms/Testimonials";
import Solutions from "../components/organisms/Solutions";
import Faqs from "../components/organisms/Faqs";
import Features from "../components/organisms/Features";
import Footer from "../components/organisms/Footer";

const sort = (first, second) => {
    const f = first.node.original.src.substring(first.node.original.src.lastIndexOf("/"))
    const s = second.node.original.src.substring(second.node.original.src.lastIndexOf("/"))
    if (f < s) return -1
    else return 1
}

const MainPage = (props: { data }) => {
    return <>
        <Header brand={props.data.brand} website={props.data.website}
            coverImage={
                props.data.images.find((i) => i.node.original.src.includes("coverImage"))
            }
        />
        <Stack 
            justifyContent={'center'}
            pt={5}
        sx={{
            backgroundColor: "background.main",
            color: "primary.main"
        }}>
            <Container>
                <Stack spacing={5} pb={5}>
                    <Products
                        products={props.data.products}
                        style={props.data.website.live.layout}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("products"))
                                .sort(sort)
                        } />
                    <Solutions
                        solutions={props.data.solutions}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("solutions"))
                                .sort(sort)
                        } />
                    <Steps
                        steps={props.data.steps}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("steps"))
                                .sort(sort)
                        } />
                    <Features
                        features={props.data.features}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("features"))
                                .sort(sort)
                        } />
                    <Faqs
                        faqs={props.data.faqs}
                    />
                    <Testimonials
                        testimonials={props.data.testimonials}
                        images={
                            props.data.images.filter((i) => i.node.original.src.includes("testimonials"))
                                .sort(sort)
                        } />
                </Stack>
            </Container>
            {props.data.products[0] || props.data.solutions[0] || props.data.steps[0] || props.data.features[0] || props.data.faqs[0] || props.data.testimonials[0] ? <Footer brand={props.data.brand} website={props.data.website} /> : <Container>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption" component="p">
              {props.data.brand.name} © 2022
            </Typography>
            <Typography variant={"subtitle1"}>
              Made with{" "}
              <Link
                href={"https://tryevergrow.com"}
                target="_blank"
                rel="noopener"
                fontWeight="bold"
                underline="none"
                color={"inherit"}
              >
                Evergrow
              </Link>
            </Typography>
          </Stack>
        </Container>}
        </Stack>
    </>
}


export default MainPage