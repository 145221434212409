import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SideBySideTile from "../atoms/SideBySideTile";

const Steps = (props) => {
  return (
    <>
      {props.steps?.length !== 0 ? (
        <>
          <Stack textAlign="center" py={4}>
            {props.sectionTitles && (
              <Typography variant="h2" color="primary.light">
                How it works
              </Typography>
            )}
          </Stack>
          {props.steps.map((step, index) => {
            return (
              <SideBySideTile
                key={"step-" + index}
                name={step.name}
                description={step.description}
                image={props.images[index]?.node?.fluid?.src}
                index={index}
              />
            );
          })}
        </>
      ) : undefined}
    </>
  );
};

export default Steps;
