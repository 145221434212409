import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LargeGridTile from "../atoms/LargeGridTile";

const Faqs = (props) => {
  return (
    <>
      {props.faqs?.length !== 0 ? 
        <>
          <Stack textAlign="center" py={4}>
                    {props.sectionTitles &&
                    <Typography variant='h2' color='primary.light'>
                    Products
                </Typography>}
                </Stack>
         <Box sx={{flexGrow: 1}}>
         <Grid container spacing={3}>
            {props.faqs.map((faq, index) => {
              return (
                <LargeGridTile
                key={"faq-" + index}
                  name={faq.name}
                  description={faq.description}
                />
              );
            })}
          </Grid>
         </Box>
        </>
       : undefined}
    </>
  );
};

export default Faqs;
